import React from 'react'
import PropTypes from "prop-types"
import styles from './button.module.css'

const Button = ({children, clicked}) => {
  return (
    <button className={styles.Button} onClick={clicked}>
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.string,
  clicked: PropTypes.func
}

export default Button