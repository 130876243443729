import React from "react"
import PropTypes from "prop-types"
import styles from "./fullWidthContent.module.css"

const FullWidthContent = (props) => { 

  const containerClassName = styles.ContainerTop;

  return (
    <div className={containerClassName}>
        <div className={styles.Content}>{props.children}</div>
    </div>
  )
}

FullWidthContent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FullWidthContent

