import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';

import styles from './modal.module.css';
import Backdrop from '../backdrop/backdrop';

const Modal = ({show, children, closeModal, title}) => {
	return show ? (
		<React.Fragment>
			<Backdrop show={show} clicked={closeModal} />
			<div
				className={styles.Modal}
				style={{
					transform: show ? 'translateY(0)' : 'translateY(-100vh)',
					opacity: show ? '1' : '0'
				}}
			>
				<div className={styles.Actions}>
					<button className={styles.toggleButton} onClick={closeModal}>
						<FontAwesomeIcon icon={faWindowClose} />
					</button>
				</div>

				<div className={styles.Content}>
					<h2>{title}</h2>
					{children}
				</div>
			</div>
		</React.Fragment>
	) : null;
};

export default Modal;
