import React from "react"
import PropTypes from "prop-types"
import styles from "./fullWidth.module.css"
import DynamicImage from "../ui/dynamicImage/dynamicImage"

const FullWidth = props => {
  const containerClassName =
    props.imagePosition === "top" ? styles.ContainerTop : styles.ContainerBottom

  return (
    <div className={props.starter ? props.starter : containerClassName}>
      {props.filename ? (
        <div className={styles.Item}>
          <DynamicImage
            alt={props.alt ? props.alt : props.filename}
            filename={props.filename}
          />
        </div>
      ) : null}
      {props.children ? (
        <div className={styles.Item} className={styles.ItemContent}>
          <div className={styles.Content}>{props.children}</div>
        </div>
      ) : null}
    </div>
  )
}

FullWidth.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FullWidth
