import React from "react"
import PropTypes from "prop-types"
import styles from "./imageContentBlock.module.css"
import DynamicImage from "../ui/dynamicImage/dynamicImage"

const ImageContentBlock = props => {
  const containerClassName =
    props.imagePosition === "right"
      ? styles.ContainerRight
      : styles.ContainerLeft

  return (
    <div className={containerClassName}>
      <div className={styles.Item}>
        <DynamicImage
          alt={props.alt ? props.alt : props.filename}
          filename={props.filename}
        />
      </div>
      <div
        className={styles.Item}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={styles.Content}>{props.children}</div>
      </div>
    </div>
  )
}

ImageContentBlock.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ImageContentBlock
