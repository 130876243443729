import React, {useState} from 'react';
import Button from '../ui/button/button';
import Modal from '../ui/modal/modal';
import ContactForm from '../contact/contact';

const ModalCombiner = (props) => {
	const [ showModal, setShowModal ] = useState(false);

	const showModalHandler = () => {
		setShowModal(true);
	};

	const closeModalHandler = () => {
		setShowModal(false);
	};

	return (
		<React.Fragment>
			<Button clicked={showModalHandler}>Kontakt</Button>
			<Modal show={showModal} closeModal={closeModalHandler} title="Kontakt">
				<ContactForm />
			</Modal>
		</React.Fragment>
	);
};

export default ModalCombiner;
