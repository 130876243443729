import React from 'react';
import {navigate} from 'gatsby-link';
import styles from './contact.module.css';
// import Button from '../ui/button/button';

function encode(data) {
	return Object.keys(data).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');
}

function Contact() {
	const [ state, setState ] = React.useState({});

	const handleChange = (e) => {
		setState({...state, [e.target.name]: e.target.value});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;
		fetch('/', {
			method: 'POST',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			body: encode({
				'form-name': form.getAttribute('name'),
				...state
			})
		})
			.then(() => navigate(form.getAttribute('action')))
			.catch((error) => alert(error));
	};

	return (
		<React.Fragment>
			<form
				className={styles.ContactForm}
				name="contact"
				method="post"
				action="/tak/"
				data-netlify="true"
				data-netlify-honeypot="bot-field"
				onSubmit={handleSubmit}
			>
				{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
				<div>
					<input type="hidden" name="form-name" value="contact" />
					<p hidden>
						<label>
							Don’t fill this out: <input name="bot-field" onChange={handleChange} />
						</label>
					</p>
					<p>
						<label id="mail">
							Din email:
							<br />
							<input type="email-input" name="email" onChange={handleChange} required />
						</label>
					</p>
					<p>
						<label id="message">
							Besked:
							<br />
							<textarea
								name="message-input"
								onChange={handleChange}
								style={{minHeight: '140px'}}
								required
							/>
						</label>
					</p>
					<p>
						<button className={styles.Button} type="submit">
							Send
						</button>
					</p>
				</div>
			</form>
		</React.Fragment>
	);
}

export default Contact;
